import React, { useState } from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch } from "react-redux";
import { CustomerEnableDisableAction } from "../../redux/actions/customerAction";
import TableImage from "../../shared/TableCard/TableImage";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar } from "primereact/calendar";
import SendNotification from "../Notifications/SendNotification";

export default function CustomerTable({ heading, allCustomers, loading, history, search, setSearch, onDelete, headerContent }) {
    const dispatch = useDispatch();
    const [selectedRow, setSelectedRow] = useState([]);
    const actions = (d) => {
        return (
            <div className="flex justify-content-around">
                <i className="pi pi-eye cursor-pointer" onClick={() => history.push(`customers/view/${d._id}`)}></i>
                <i className="pi pi-pencil cursor-pointer" onClick={() => history.push(`customers/edit/${d._id}`)}></i>
                <i className="pi pi-trash cursor-pointer" onClick={(e) => onDelete(e, d._id)}></i>
            </div>
        );
    };

    const handleSwitchChange = (id) => {
        dispatch(CustomerEnableDisableAction(id));
    };

    const isActive = (rowData) => {
        return (
            <div className="flex">
                <InputSwitch checked={!rowData.isDeleted} onChange={() => handleSwitchChange(rowData?._id)} />
            </div>
        );
    };

    const image = (r) => {
        const image = r.image;
        return <TableImage image={image} />;
    };
    const dateFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => {
                    options.filterCallback(e.value, options.index);
                }}
                dateFormat="dd/mm/yy"
                placeholder="dd/mm/yyyy"
                mask="99/99/9999"
            />
        );
    };
    const filters = {
        time: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
        subscriptionExpireOn: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    };
    const formatDate = (date) => {
        return date.toLocaleDateString("en-IN", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    };

    return (
        <>
            {selectedRow?.length > 0 && <SendNotification selectedRow={selectedRow} setSelectedRow={setSelectedRow} />}
            <TableCard title={heading} onSearch={setSearch} searchKeyword={search} headerContent={headerContent()}>
                <DataTable
                    paginator
                    value={allCustomers}
                    selection={selectedRow}
                    onSelectionChange={(e) => setSelectedRow(e.value)}
                    selectionMode="checkbox"
                    showGridlines
                    filter={filters}
                    filterDisplay="menu"
                    rows={10}
                    loading={loading}
                    emptyMessage="No customer found."
                >
                    <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                    <Column body={(rowData) => rowData.firstName + " " + rowData.lastName ?? ""} header="Name"></Column>
                    <Column field="email" header="Email" style={{ width: "250px" }}></Column>
                    <Column field="mobile" header="Mobile" style={{ width: "250px" }}></Column>
                    <Column
                        header="Date"
                        field="time"
                        dataType="date"
                        style={{ minWidth: "10rem" }}
                        filterMatchMode="dateIs"
                        showFilterMatchModes={true}
                        body={(rowData) => formatDate(rowData.time)}
                        filter
                        filterElement={dateFilterTemplate}
                    />
                    <Column
                        header="Subscription Expire Date"
                        field="subscriptionExpireOn"
                        dataType="date"
                        style={{ minWidth: "10rem" }}
                        filterMatchMode="dateIs"
                        showFilterMatchModes={true}
                        body={(rowData) => (rowData.subscriptionExpireOn ? formatDate(rowData.subscriptionExpireOn) : "")}
                        filter
                        filterElement={dateFilterTemplate}
                    />
                    <Column body={image} header="Image" style={{ width: "80px" }}></Column>
                    <Column header="Active" body={isActive} style={{ width: "80px" }}></Column>
                    <Column body={actions} style={{ width: "80px" }} header="Action"></Column>
                </DataTable>
            </TableCard>
        </>
    );
}
